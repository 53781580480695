// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-formularz-js": () => import("./../../../src/pages/formularz.js" /* webpackChunkName: "component---src-pages-formularz-js" */),
  "component---src-pages-fundacja-js": () => import("./../../../src/pages/fundacja.js" /* webpackChunkName: "component---src-pages-fundacja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapa-js": () => import("./../../../src/pages/mapa.js" /* webpackChunkName: "component---src-pages-mapa-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-static-page-template-js": () => import("./../../../src/templates/staticPageTemplate.js" /* webpackChunkName: "component---src-templates-static-page-template-js" */)
}

